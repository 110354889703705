
import { PackageViewMode } from '@/common/enums/PackageViewMode.enum';
import QuantityTicker from '@/common/components/quantity-ticker.component.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Time } from '@/common/models/Time.model';
import { Item } from '@/common/models/Item.model';
import { format } from 'date-fns';
import Currency from '@/common/components/currency.component.vue';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import ActivitySelector from '../components/activity-selector.component.vue';

@Options({
	name: 'TimeView',
	components: { QuantityTicker, Currency, ActivitySelector },
})
export default class TimeView extends Vue {
	@Prop({ default: '0' }) clientLocationKey!: string;
	@Prop({ default: '0' }) type!: PackageViewMode;
	@Prop({ default: '0' }) typekey!: string;
	format = format;
	created(): void {
		if (orderModule.itemActivities.length == 0) {
			return;
		}
		console.log('Calling GBT from Timeview created');

		orderModule.setOpenActivity(orderModule.itemActivities[0].entityHierarchyKey);
		orderModule.openActivity?.getBookTimes().finally(() => {
			//if Date only, auto select the one time, and move forward
			if (this.activity?.activity.AppointmentTypeKey == 2 && this.times[0]) {
				this.activity?.selectTime(this.times[0]);
				this.next();
			} else if (this.times.length == 1) {
				this.activity?.selectTime(this.times[0]);
			}
		});
	}
	update() {
		console.log('Calling GBT from Time view update');

		this.activity?.getBookTimes().finally(() => {
			if (this.times.length == 1) {
				this.activity?.selectTime(this.times[0]);
			}
		});
	}
	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get times(): Time[] {
		return orderModule.openActivity?.availableTimes.filter((time) => !time.CallToBook) ?? [];
	}
	get isPackage(): boolean {
		return !!this.item?.PackageKey;
	}
	get item(): Item | null {
		return orderModule.openItem;
	}

	get canProceed(): boolean {
		return !!this.activity?.selectedTimes.length;
	}
	next(): void {
		if (orderModule.allTimesSelected) {
			var dest = orderModule.hasAddons ? 'addon' : 'checkout';
			this.$router.push(`/portal/${this.clientLocationKey}/${this.type}/${this.typekey}/book/${dest}`);
		} else if (this.activity?.selectedTimes.length ?? 0) {
			let nextUnfinished = orderModule.itemActivities.find((activity) => !activity.selectedTimes.length);
			orderModule.setOpenActivity(nextUnfinished?.entityHierarchyKey ?? 0);
			this.update();
		}
	}
	warningFor(time: Time): string | null {
		//check other activity selectedTimes and see if this time will produce a conflict
		//currently dont have the data needed to make this calculation. Activities need a duration numerical value
		var valid = true;
		// if (orderModule.openActivity == null) {
		// 	return null;
		// }
		// orderModule.itemActivities.forEach((a) => {
		// 	if (a.entityHierarchyKey == orderModule.openActivity?.entityHierarchyKey) {
		// 		return;
		// 	}
		// 	a.selectedTimes.forEach((time) => {
		// 		let currentDuration = orderModule.openActivity?.activity.
		// 	});
		// });
		return valid ? null : 'Time conflict';
	}
	isSelected(time: Time): boolean {
		return !!this.activity?.selectedTimes.find((t) => t.Value == time.Value) ?? false;
	}
	select(time: Time): void {
		console.log('trying to set time', time);
		this.activity?.selectTime(time);
	}
	get selectedTimes(): Time[] {
		return this.activity?.selectedTimes ?? [];
	}
}
