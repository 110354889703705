
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'QuantityTicker',
	components: {},
	emits: ['update:modelValue'],
})
export default class QuantityTicker extends Vue {
	@Prop() modelValue!: number;
	@Prop({ default: null }) min!: number | null;
	@Prop({ default: null }) max!: number | null;
	@Prop({ default: 1 }) stepSize!: number;

	get value(): number {
		return this.modelValue;
	}

	set value(v: number) {
		this.update(Number(v));
	}

	increase(): void {
		this.update(this.modelValue + this.stepSize);
	}

	decrease(): void {
		this.update(this.modelValue - this.stepSize);
	}

	update(v: number): void {
		if (typeof v !== 'number') return;
		if ((this.min != null && v < this.min) || (this.max != null && v > this.max)) return;
		this.$emit('update:modelValue', v);
	}
}
