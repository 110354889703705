
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Options, Vue } from 'vue-class-component';
import { format } from 'date-fns';
import { Prop } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import { clientModule } from '@/modules/client/client.vuex-module';
import { EntityHierarchy } from '@/common/models/EntityHierarchy.model';

@Options({
	name: 'ActivitySelector',
	components: {},
	emits: ['onChange'],
})
export default class ActivitySelector extends Vue {
	@Prop({ default: 'rate' }) validate: 'rate' | 'date' | 'time' | 'addon' = 'rate';
	select(a: OrderActivity): void {
		orderModule.setOpenActivity(a.entityHierarchyKey);
		this.$emit('onChange', a);
	}
	get item(): Item | null {
		return orderModule.openItem;
	}
	get isPackage(): boolean {
		return !!this.item?.PackageKey;
	}
	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get activities() {
		return orderModule.itemActivities.filter((a) => {
			switch (this.validate) {
				case 'rate':
					return a.rates.length;
				case 'date':
					return true;
				case 'time':
					return true;
				case 'addon':
					return a.activity.AddOns.length;
			}
		});
	}
	clientLocationActivity(key: number): EntityHierarchy | undefined {
		return clientModule.location?.Activities.find((a) => a.EntityHierarchyKey == key);
	}
	classFor(activity: OrderActivity): string {
		var valid = 'gg-check-o';
		var invalid = 'gg-danger';
		var value = false;
		switch (this.validate) {
			case 'rate':
				value = activity.rateCount > 0;
				break;
			case 'date':
				value = !!activity.selectedDate;
				break;
			case 'time':
				value = !!activity.selectedTimes.length;
				break;
			case 'addon':
				value = !!activity.orderAddons.length;
				break;
		}
		return value ? valid : invalid;
	}
	contentFor(activity: OrderActivity): string {
		var value = '';
		switch (this.validate) {
			case 'rate':
				var rates = activity.selectedRates;
				value = rates.length
					? rates
							.map((rate) => `${rate.Participants} x ${rate.Name}`)
							.join(', ')
							.substring(0, 40)
					: 'Select guests';
				break;
			case 'date':
				var date = activity.selectedDate;
				value = date ? format(date, 'eeee, MMM d, yyyy') : 'Select date';
				break;
			case 'time':
				var timeString = activity.selectedTimes.map((time) => time.DisplayName).join(', ');
				value = timeString == '' ? 'Select time' : timeString;
				break;
			case 'addon':
				var addons = activity.selectedAddons.length;
				value = addons ? `${addons} product${addons == 1 ? '' : 's'}` : 'Select addons';
				break;
		}
		return value;
	}
}
